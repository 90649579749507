<template>
  <div class="postModal">
    <div class="postContent">
      <div class="title">发布至</div>
      <div class="f_flex f_a_c f_j_sa" style="margin-top: 47px">
        <div class="f_flex f_col f_j_c f_a_c" @click="goPostPage(1)">
          <img class="postImg" src="../../assets/post_group.png" alt="">
          <div class="postTitle">工作群</div>
        </div>
        <div class="f_flex f_col f_j_c f_a_c" @click="goPostPage(2)">
          <img class="postImg" src="../../assets/post_sq.png" alt="">
          <div class="postTitle">广场</div>
        </div>
        <div class="f_flex f_col f_j_c f_a_c" @click="goPostPage(3)">
          <img class="postImg" src="../../assets/post_sc.png" alt="">
          <div class="postTitle">私密本</div>
        </div>
      </div>
      <div class="close transform_r_c" @click="close">
        <i class="icon-close iconfont"></i>
      </div>
    </div>

    <popup key='inside' close-on-popstate round v-model="showGroupList" position="bottom" :style="{height: groupCount >= 10 ? '90%' : '50%'}">
      <div style="padding-top: 20px; padding-bottom: 40px" v-if='resource == 1'>
        <div style="margin: 0 15px; margin-bottom: 20px" v-for='(item, index) in groupList' :key='index'>
          <div class="buileTitle">{{item.company.companyName}}</div>
          <div
            v-for='(ii, key) in item.groupList'
            class="iitem"
            :key='key'>
            <group-item @pick='goPostGroupFn(item, ii)' :companyInfo="item.company" :disable="false" :companyId='item.company.companyId' :info='ii'></group-item>
          </div>
        </div>
      </div>
      <div v-else style="padding: 20px;">
        <div class="companyItem" @click="goNext(item)" v-for='(item, index) in companyList' :key="index">
          {{item.companyName}}
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import { Popup } from 'vant';
import groupListMixins from '../mixins/groupList'
import groupItem from '../group/groupItem.vue'
import postInit from '../mixins/post.init.js'

export default {
  components: {
    Popup,
    groupItem
  },
  mixins: [groupListMixins, postInit],
  data: ()=> ({
    showGroupList: false,
    groupList: [],
    resource: 1,
  }),
  computed: {
    groupCount() {
      const list = this.groupList
      let t = 0
      list.forEach(item => {
        if (item && item.groupList) {
          t += item.groupList.length
        }
      });
      return t
    }
  },
  methods: {
    goNext(item) {
      if (this.resource == 2) {
        this.goSquareFn(item)
      } else if (this.resource == 3) {
        this.goPrivate(item)
      }
    },
    goPrivate(item) {
      const companyInfo = this.$global.getItem('companyInfo', true)
      const obj = {
        postsSource: 3
      }
      if (companyInfo) {
        obj.companyId = item.companyId || companyInfo.companyId
        obj.keyword = item.companyKeyword || companyInfo.companyKeyword
      }
      this.$router.push({
        path: '/post',
        query: obj
      })
      this.postInstAction()
      this.showGroupList = false
      this.close()
    },
    goPostGroupFn(item, ii) {
      // const companyInfo = this.$global.getItem('companyInfo', true)
      this.$router.push(`/post?postsSource=1&groupId=${ii.groupId}&companyId=${item.company.companyId}&groupName=${ii.groupName}&keyword=${item.company.companyKeyword}`)
      this.postInstAction()
      this.showGroupList = false
      this.close()
    },
    goSquareFn(item) {
      this.$router.push(`/post?postsSource=2&companyId=${item.companyId}&keyword=${item.companyKeyword}`)
      this.postInstAction()
      this.showGroupList = false
      this.close()
    },
    goPostPage(item) {
      const auth = this.$global.getItem('auth')
      if (auth && auth.userAuthMap) {
        // console.log(auth);
        const {publicSquareIconAuth, groupIconAuth} = auth.userAuthMap
        if (!groupIconAuth || (groupIconAuth.hasAuth == 2 && item == 1)) {
          this.$toast(groupIconAuth.hasNotAuthTips)
          return
        }

        if (!publicSquareIconAuth || (publicSquareIconAuth.hasAuth == 2 && item == 2)) {
          this.$toast(publicSquareIconAuth.hasNotAuthTips)
          return
        }
      }

      this.resource = item

      if (item == 1) {
        this._getCompanyGroupList().then(res => {
          if (res.data) {
            this.groupList = res.data.companyGroupList
            if (this.groupList.length) {
              this.showGroupList = true;
              return;
            }
          }
          this.$toast('未分配角色用户不能发群贴')
        })
      } else if (item == 2) {
        const companyInfo = this.$global.getItem('companyInfo', true)
        const list = this.$global.getItem('companyInfoList', true)
        this.companyList = list
        if (companyInfo && companyInfo.companyId && companyInfo.auditingStatus == 1) {
          if (list.length >1) {
            this.showGroupList = true;
            return;
          }
          
          this.$router.push(`/post?postsSource=2&companyId=${companyInfo.companyId}&keyword=${companyInfo.companyKeyword}`)
          this.postInstAction()
          this.close()
        } else {
          this.$toast('未分配角色用户不能发广场')
        }
      } else {
        // const companyInfo = this.$global.getItem('companyInfo', true)
        const obj = {
          postsSource: 3
        }
        // const list = this.$global.getItem('companyInfoList', true)
        // this.companyList = list
        // if (companyInfo) {
        //   this.showGroupList = true
        //   obj.companyId = companyInfo.companyId
        //   return;
        // }
        this.$router.push({
          path: '/post',
          query: obj
        })
        this.postInstAction()
        this.close()
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.postModal {
  position: relative;
  width: 100%;
  height: 100%;
  .postContent {
    position: absolute;
    width: 100%;
    height: 290px;
    bottom: 0;
    left: 0;
  }
  .title {
    position: relative;
    font-size: 14px;
    text-align: center;
    color: #9B9B9B;
    .c {
      content: "";
      position: absolute;
      width: 110px;
      height: 1px;
      background: #E4E4E4;
      top: 50%;
      transform: translateY(-50%);
    }
    &::before {
      .c;
      left: 45px;
    }
    &::after {
      .c;
      right: 45px;
    }
  }
  .postImg {
    width: 54px;
    height: 54px;
    margin-bottom: 6px;
  }
  .postTitle {
    font-size: 14px;
    color: #4A4A4A;
  }
  .close {
    position: absolute;
    left: 50%;
    bottom: 84px;
  }
  .icon-close {
    font-size: 25px;
    font-weight: 700;
    color: #8A8A8A;
  }
}
.companyItem {
  height: 35px;
  font-size: 16px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}
</style>
