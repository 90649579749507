<template>
  <tab :name='name' :dot='dot'>
    <div slot='title' :style='{fontSize: act==this.name ? "17px" : "14px"}'>@我</div>
    <div class="tabPage" ref='wrap' @scroll="scrollFn">
      <pull-refresh v-model="isLoading" @refresh="init">
        <div>
          <div style="height: 44px;" v-if='get_noticeMsg && get_noticeStatus && showNotice'></div>
          <empty-page :show='list.length>0'>
            <div style="padding: 0 3px" ref='content'>
              <info-panel
                v-for='(item, index) in list' 
                :key='index'
                :info='item' />
              <div class="endTitle" v-if='list.length && isEnd'>{{endTitle}}</div>
            </div>
          </empty-page>
        </div>
      </pull-refresh>
    </div>
  </tab>
</template>

<script>
import { Tab, PullRefresh } from 'vant';
import infoPanel from '../simpleInfo.vue'
import emptyPage from '@/components/emptyPage/index.vue'
import getList from '../mixins/getList'
import postApi from '@/api/post'
import getStoreMixins from '@/pages/home/mixins/getStore'

export default {
  components: {
    Tab,
    emptyPage,
    infoPanel,
    PullRefresh
  },
  props: {
    act: String,
    refresh: {
      type: Number,
      default: 0
    },
    dot: Boolean
  },
  data: ()=> ({
    isShowFirst: false,
    name: 'atMe',
    pageNo: 1,
    pageSize: 20,
    isLoading: false,
    isEnd: false,
    list: [],
    endTitle: '',
    showNotice: true
  }),
  mixins: [getList, getStoreMixins],
  watch: {
    // act(n) {
    //   if (n === this.name) {
    //     this.init()
    //   }
    // },
    refresh() {
      this.init()
    }
  },
  methods: {
    scrollFn(e) {
      this.$global.debounce(()=> {
        this.$router.history.current.meta.offsetTop = e.target.scrollTop
        const wrapH = this.$refs.wrap.clientHeight
        const contentH = this.$refs.content.clientHeight
        if (contentH - e.target.scrollTop - wrapH <= 80) {
          this.getList()
        }
      })
    },
    getList() {
      if (!this.isEnd) {
        this.$load.show('加载中...')
        const companyInfo = this.$global.getItem('companyInfo', true)
        postApi.postAtMe({
          companyId: companyInfo?.companyId,
          pageNo: this.pageNo,
          pageSize: this.pageSize
        }).then(res => {
          this.$load.hide()
          if (res.data) {
            this.list.push(...res.data.remindMeList)
            if (res.data.endFlag) {
              this.isEnd = true
              this.endTitle = res.data.endTitle
            } else {
              this.pageNo++
              this.endTitle = '正在加载中...'
            }
            if (res.data.remindMeTips) {
              this.$notify({ type: 'primary', message: res.data.remindMeTips })
            }
          }
        }).catch(()=> {
          this.$load.hide()
        })
      }
    },
    init() {
      if (this.act === this.name) {
        this.$router.history.current.meta.tab = this.name
        // this._initList()
        this.$set(this, 'list', [])
        this.pageNo = 1
        this.isEnd = false
        this.isLoading = false
        this.isShowFirst = false
        this.getList()
        this.noticeFn()
      }
    },
    scrollTopFn(h) {
      setTimeout(() => {
        this.$refs.wrap.scrollTop = h
      }, 20)
    },
    noticeFn() {
      this.showNotice = true
      const t = 33 * 1000
      setTimeout(() => {
        this.showNotice = false
      }, t)
    }
  },
  mounted() {
    this.init()
    // this.$route.meta.change = ()=> {
    //   this.init()
    // }
  }
}
</script>


<style lang="less" scoped>
@import url('~@/pages/home/mixins/tabStyle.less');
</style>