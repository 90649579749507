<template>
  <page :styleWrap='{background: "#fff"}' :footer='80' ref='wrap'>
    <div>
      <div class="search_wrap f_flex f_a_c f_j_sb">
        <div class="f_g1 searchPanel f_flex f_a_c">
          <i class="iconfont icon-search1" style="color: #C4C4C4;"></i>
          <form  @submit.prevent action="#" style="width: 100%">
          <input type="search" style='font-size: 14px; background: #F1F1F1; margin-left: 12px; border: 0; width: 100%'
            placeholder="搜索"
            v-model="searchWord"
            @keydown.enter="prepareSearch"
           />
          </form>
        </div>
        <!-- <search class="f_g1" disabled @click.native="$router.push('/search')" style="border-radius: 8px; padding: 0" background="#f6f6f6" placeholder="搜索"></search> -->
        <div style="margin-left: 9px;" class="f_flex f_a_c">
          <!-- <div class="refreshIcon f_flex f_a_c f_j_c" :class="{rotateAnimate: isRefresh}">
            <i class="iconfont icon-refresh theme_c" @click='needRefresh'></i>
          </div> -->
          <div v-if='companyInfo'>
            <popup-dialog
              v-if='companyInfo'
              key='home'
              v-model='changeCompanyModel'
              text='companyShortName'
              @pick='pickCompanyInfo'
              :triangleStyle='{left: "60%"}'
              :dialogStyle='{width: "120px", left: 0}'
              :id='companyInfo.companyId'
              value='companyId'
              :title='companyInfo.companyShortName'
              :list='companyInfoList'>
            </popup-dialog>
          </div>
        </div>
      </div>
      <tabs :ellipsis='false' v-model="active" @change='changeTab' color='#63A8FF' line-height='4' :line-width='34' title-inactive-color='#9B9B9B'>
        <div class='noticMsg' v-if='get_noticeMsg && showNotice' @click="$router.push('/infoCenter')">
          <notice-bar
            background='#FFF5D1'
            :left-icon='icon_notice'
            style="color: #8B572A;"
            :text="get_noticeMsg">
            <div slot="right-icon" class="f_flex f_a_c">
              更多
              <div class="iconfont icon-ARROW" style="font-size: 20px"></div>
            </div>
          </notice-bar>
        </div>
        <tab-common ref='zonghe' :act='active' :refresh="refresh" title='动态' :bizType="6" name='zonghe'></tab-common>
        <tab-admin ref='admin' v-if='role=="admin"' :act='active' :refresh="refresh"></tab-admin>
        <tab-common ref='myPost' :act='active' :refresh="refresh" title='我的' :bizType="1" name='myPost'></tab-common>
        <tab-common ref='attention' :act='active' :refresh="refresh" title='关注' :bizType="2" name='attention'></tab-common>
        <tab-atme ref='atMe' :dot='newStatus' :act='active' :refresh="refresh"></tab-atme>
        <tab-common ref='square' :act='active' :refresh="refresh" title='广场' :styleNoEvent='false' :bizType="3" name='square'></tab-common>
        <tab-common ref='private' :act='active' :showStar='false' :styleNoEvent='false' :refresh="refresh" title='私密本' :bizType="4" name='private'></tab-common>
      </tabs>
      <div style="height: 100px"></div>
      <div>
        <img @click="postFn" class="postIcon transform_r_c act_link" src="../../assets/icon_post.png" alt="">
      </div>
      <popup key='outside' @close='showPost=false' close-on-popstate v-model="showPost" position="bottom" :style="{ height: '100%' }">
        <post-content @close='showPost=false'></post-content>
      </popup>
       <popup key='inside' close-on-popstate round v-model="showGroupList" position="bottom" :style="{height: groupCount >= 10 ? '90%' : '50%'}">
        <div style="padding-top: 20px; padding-bottom: 40px">
          <div style="margin: 0 15px; margin-bottom: 20px" v-for='(item, index) in groupList' :key='index'>
            <div class="buileTitle">{{item.company.companyName}}</div>
            <div
              v-for='(ii, key) in item.groupList'
              class="iitem"
              :key='key'>
              <group-item @pick='goPostGroupFn(item, ii)' :companyInfo="item.company" :disable="false" :companyId='item.company.companyId' :info='ii'></group-item>
            </div>
          </div>
        </div>
      </popup>
    </div>

    <div slot="footer">
      <bottom-menu></bottom-menu>
    </div>
  </page>
</template>

<script>
import { Tabs, Popup, NoticeBar } from 'vant';
import bottomMenu from '@/components/bottomMenu/index.vue'
import postContent from './postContent.vue'
import getPost from './mixins/getList'
import TabAtme from './tabs/atMe.vue'
import TabAdmin from './tabs/examine.vue'
import {mapGetters, mapActions} from 'vuex'
import popupDialog from '@/components/popupDialog/index.vue'
import tabCommon from './tabs/tabCommon.vue'
import userApi from '@/api/user'
import noticeIcon from '@/assets/icon_notice.png'
import postApi from '@/api/post'
import scrollTop from './mixins/top.js'
import getAuthMixins from '@/common/mixins/getAuth'
import groupApi from '@/api/group'

import groupListMixins from '../mixins/groupList'
import postInit from '../mixins/post.init.js'
import groupItem from '../group/groupItem.vue'

export default {
  data: ()=> ({
    active: 'zonghe',
    showPost: false,
    role: '',
    wrap: null,
    refresh: 0,
    isRefresh: false,
    companyInfoList: [],
    icon_notice: noticeIcon,
    companyInfo: null,
    changeCompanyModel: false,
    newStatus: false,
    showNotice: true, // 展示notice
    timer: null,
    showGroupList: false,
    groupList: [],
    resource: 1,
    searchWord: '',
  }),
  mixins: [getPost, scrollTop, getAuthMixins, groupListMixins, postInit],
  components: {
    Tabs,
    bottomMenu,
    Popup,
    NoticeBar,
    postContent,
    TabAdmin,
    TabAtme,
    // Search,
    popupDialog,
    tabCommon,
    groupItem
  },
  computed: {
    ...mapGetters([
      'get_noticeMsg',
      'get_postStatus',
      'get_refresh',
      'get_searchWord'
    ]),
    groupCount() {
      const list = this.groupList
      let t = 0
      list.forEach(item => {
        if (item && item.groupList) {
          t += item.groupList.length
        }
      });
      return t
    }
  },
  watch: {
    get_postStatus(n) {
      if (n && n === 'success') {
        this.refresh = new Date().getTime()
      }
    },
    get_refresh(n) {
      // console.log(n);
      if (n) {
        this.refresh = new Date().getTime()
      }
    }
  },
  methods: {
    ...mapActions(
      ['set_noticeStatus', 'set_refresh', 'set_searchWord']
    ),
    prepareSearch() {
      this.set_searchWord(this.searchWord)
      this.$router.push ({
        path: '/search',
        query: {
          from: 'search'
        }
      })
    },
    goPostGroupFn(item, ii) {
      // const companyInfo = this.$global.getItem('companyInfo', true)
      this.$router.push(`/post?postsSource=1&groupId=${ii.groupId}&companyId=${item.company.companyId}&groupName=${ii.groupName}&keyword=${item.company.companyKeyword}`)
      //this.postInstAction()
      this.showGroupList = false
      // this.close()
    },
    _getCompanyGroupList(groupStatus=1) {
      return new Promise((resolve)=> {
        groupApi.groupList({
          groupStatus: groupStatus,
          companyId: JSON.parse (window.localStorage.getItem ('companyInfo')).companyId
        }).then(res => {
          resolve(res)
          // res=> {data: {companyGroupList: [{company: {name,id}, groupList: {groupName, groupId...}}]}}
        })
      })
    },
    postFn() {
      const companyId = JSON.parse(window.localStorage.getItem('companyInfo')).companyId
      if(this.active == 'private') {
        this.$router.push('/post?postsSource=3')
      } else if(this.active == 'square'){
        this.$router.push(`/post?postsSource=2&companyId=${companyId}&keyword=主题号`)
      } else {
        this._getCompanyGroupList().then(res => {
          if (res.data) {
            this.groupList = res.data.companyGroupList
            if (this.groupList.length) {
              this.showGroupList = true;
              return;
            }
          }
          this.$toast('未分配角色用户不能发群贴')
        })
      }

    },
    hasNews() {
      const companyInfo = this.$global.getItem('companyInfo', true) || {}
      postApi.remindMeStatus({companyId: companyInfo.companyId}).then(res => {
        if (res.data) {
          this.newStatus = res.data.noticeRemindStatus == 1
        }
      })
    },
    changeTab(e) {
      this.refresh = new Date().getTime()
      this.$router.history.current.meta.tab = e
    },
    isChangeCompany(cb) {
      const changeCompany = this.$global.getItem('changeCompany')
      if (changeCompany && changeCompany == 1) {
        cb && cb()
        setTimeout(()=> {
          this.$global.setItem('changeCompany', 0)
        })
      }
    },
    getRole() {
      const auth = this.$global.getItem('auth')
      if (auth) {
        const groupAuth = auth.userAuthMap?.postsAuditingAuth?.hasAuth
        this.role = groupAuth && groupAuth==1 ? 'admin' : ''
        // this.role = Math.random() > 0.5 ? 'admin' : '' // test
      } else {
        const companyInfo = this.$global.getItem('companyInfo', true)
        const companyInfoList = this.$global.getItem('companyInfoList', true)
        if (companyInfoList && companyInfoList.length) {
          if (companyInfo) {
            this.role = companyInfo.postsAuditingAuth == 1 ? 'admin' : ''
          } else {
            this.role = companyInfoList[0].postsAuditingAuth == 1 ? 'admin' : ''
          }
        }
      }
      if (this.role !== 'admin' && this.active == 'admin') {
        this.active = 'zonghe'
      }
    },
    // 手动刷新
    needRefresh() {
      this.isRefresh = true
      this.refresh = new Date().getTime()
      setTimeout(() => {
        this.isRefresh = false
      }, 1000)
    },
    getUserInfo() {
      userApi.queryUserInfo({}).then(res => {
        if (res.data) {
          this.$global.setItem('userInfo', res.data.userInfo, true)
          this.$global.setItem('userInfo', res.data.userInfo)

          const {userAuthMap} = res.data
          this.$global.setItem('auth', {
            userAuthMap
          })

          const companyInfoList = res.data.companyInfoList
          this.$set(this, 'companyInfoList', companyInfoList)
          this.$global.setItem('companyInfoList', companyInfoList, true)

          const companyInfo = this.$global.getItem('companyInfo', true)
          this.$set(this, 'companyInfo', companyInfo)
          if(!companyInfo && companyInfoList.length) {
            this.$global.setItem('companyInfo', companyInfoList[0], true)
          }

          if(companyInfoList && companyInfoList.length) {
            if (companyInfo) {
              this.getAuth(companyInfo.companyId).then(()=> {
                this.getRole()
              })
            } else {
              this.getAuth(companyInfo.companyId).then(()=> {
                this.getRole(companyInfoList[0].companyId).then(()=> {
                  this.getRole()
                })
              })
            }
          }
        }
        // this.hasNews()
      })
    },
    getCompanyInfoList() {
      userApi.queryCompanyInfoList({}).then(res => {
        if(res.data) {
          const companyInfoList = res.data.companyInfoList
          this.$set(this, 'companyInfoList', companyInfoList)
          this.$global.setItem('companyInfoList', companyInfoList, true)
          const companyInfo = this.$global.getItem('companyInfo', true)
          if (companyInfo) {
            this.$set(this, 'companyInfo', companyInfo)
          } else {
            this.$global.setItem('companyInfo', companyInfoList[0], true)
            this.$set(this, 'companyInfo', companyInfoList[0])
          }
          this.$set(this, 'companyInfoList', companyInfoList)
        }
      })
    },

    
    pickCompanyInfo(i) {
      const {item} = i
      if (this.companyInfo) {
        if (this.companyInfo.companyId !== item.companyId) {
          this.refresh = new Date().getTime()
          this.$set(this, 'companyInfo', item)
          this.hasNews()
          this.$global.removeItem('showNotice', true)
          this.showNoticeFn()
          this.getAuth(item.companyId).then(()=> {
            this.getRole()
          })
        }
      } else {
        this.refresh = new Date().getTime()
        this.$set(this, 'companyInfo', item)
        this.hasNews()
      }

      this.$global.setItem('companyInfo', item, true)
      this.$global.setItem('companyInfo', item)
      this.changeCompanyModel = false
    },
    showNoticeFn() {
      const time = 30 * 1000
      const showNotice = this.$global.getItem('showNotice', true) || 0
      if (showNotice) {
        const now = new Date().getTime()
        const leftTime = now - Number(showNotice)
        if (leftTime >= time) {
          this.showNotice = false
          this.set_noticeStatus(false)
        } else {
          // const needTime = time - leftTime >= 0 (time - leftTime) || 0
          setTimeout(() => {
            this.showNotice = false
            this.set_noticeStatus(false)
          }, time)
        }
      } else {
        this.showNotice = true
        this.set_noticeStatus(true)
        const d = new Date().getTime()
        this.$global.setItem('showNotice', d, true)
        setTimeout(() => {
          this.showNotice = false
          this.set_noticeStatus(false)
        }, time)
      }
    },
    initCompanyInfo() {
      const companyInfo = this.$global.getItem('companyInfo', true)
      const companyInfoList = this.$global.getItem('companyInfoList', true)
      this.$set(this, 'companyInfoList', companyInfoList)
      this.$set(this, 'companyInfo', companyInfo)
    },

    setupWechatSDK() {
      var currentUrl = location.href.split('#')[0]
      console.log("currentUrl", currentUrl)
      
                userApi.getWxSignature({"url": currentUrl}).then(res => {

                    if(res) {
                        console.log("getWxSignature", res)
                        const config = res;
                        console.log("config appId", config.appId)
                            this.$wx.config({
                                debug: false, // 开启调试模式
                                appId: config.appId,
                                timestamp: config.timestamp,
                                nonceStr: config.nonceStr,
                                signature: config.signature,
                                jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"]
                            });

                            
                            

                            // // 配置“分享到朋友圈”的信息
                            // that.$wx.updateTimelineShareData({
                            //     title: '分享到朋友圈的标题', // 分享标题
                            //     link: 'https://www.yourwebsite.com/path', // 分享链接，默认为当前页面链接
                            //     imgUrl: 'https://www.yourwebsite.com/path/to/image.jpg', // 分享图标
                            //     success: function () {
                            //     // 用户点击了分享后执行的回调函数
                            //     console.log('分享到朋友圈成功');
                            //     }
                            // });
                        }

                }
            );
            },
  },

  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if (to.name === 'home') {
          // const current = new Date().getTime()
        setTimeout(() => {
          vm.showNoticeFn()
          vm.getRole()
          vm.initCompanyInfo()
          vm.restoreOffsetTop(vm.active, (top)=> {
            vm.$refs[vm.active].scrollTopFn(top)
          })
          if (!vm.companyInfoList || (vm.companyInfoList && !vm.companyInfoList.length) || !vm.companyInfo) {
            vm.getUserInfo()
          }
            vm.hasNews()
          vm.timer = setInterval(()=> {
              // const c = new Date().getTime()
              // console.log(c - current)
            vm.hasNews()
          }, 12000)
        }, 100)
      }
      if (from.name == 'group' || from.name == 'setting') {
        vm.refresh = new Date().getTime()
      }
      setTimeout(() => {
        if (!vm.get_refresh) {
          const t = new Date().getTime()
          vm.refresh = t
          vm.set_refresh(t)
        }
      }, 100)
    })
  },
  beforeRouteLeave (to, _, next) {
    if (to.name !='home') {
      clearInterval(this.timer)
      this.timer = null
    }
    next()
  },
  mounted() {
    this.getUserInfo()
    this.setupWechatSDK()
  },
}
</script>

<style lang="less" scoped>
.homeContent {
  padding: 0 15px;
}
.search_wrap {
  background: #fff;
  padding: 12px;
  padding-right: 8px;
  padding-bottom: 5px;
  box-sizing: border-box;
}
.noticMsg {
  position: sticky;
  top: 42px;
  background: #fff;
  z-index: 7;
}
.icon-refresh {
  font-size: 20px;
  font-weight: 700;
}
.rotateAnimate {
  animation: refresh 1s;
}
@keyframes refresh {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(720deg);
  }
}
.refreshIcon {
  width: 20px;
  height: 20px;
}
.searchPanel {
  background: #F1F1F1;
  border-radius: 30px;
  padding: 0 18px;
  box-sizing: border-box;
  height: 34px;
}
</style>
