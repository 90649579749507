import {mapActions} from 'vuex'

export default {
  methods: {
    ...mapActions([
      'set_styleNum',
      'set_labelList',
      'set_anchorList',
      'set_anchorType',
      'set_postStatus'
    ]),
    postInstAction() {
      this.set_styleNum(null)
      this.set_labelList([])
      this.set_anchorList([])
      this.set_anchorType(null)
      this.set_postStatus(null)
    },

  }
}



