import postApi from '@/api/post'
import {mapActions} from 'vuex';
import userApi from '@/api/user'

export default {
  data: ()=> ({
    pageNo: 1,
    list: [],
    isLoading: false,
    isEnd: false,
    pageSize: 20,
    noticeMsg: '',
    endTitle: '',
    isShowFirst: false,
    showNotice: true,
  }),
  methods: {
    ...mapActions([
      'set_noticeMsg'
    ]),
    getList(bizType) {
      // 如果是下拉刷新重置pageNo
      const isLoading = this.isLoading || false
      const pageNo = isLoading ? 1 : this.pageNo
      if (!this.isEnd) {
        const obj = {
          bizType,
          pageNo: pageNo,
          pageSize: this.pageSize
        }
        const companyInfo = this.$global.getItem('companyInfo', true)
        if(companyInfo) {
          obj.bizId = companyInfo.companyId
        } else {
          return new Promise((resolve)=> {
            userApi.queryCompanyInfoList({}).then(res => {
              if (res.data) {
                const companyInfoList = res.data.companyInfoList
                this.$global.setItem('companyInfoList', companyInfoList, true)
                this.$global.setItem('companyInfo', companyInfoList[0], true)
                if (companyInfoList[0] && companyInfoList[0].companyId) {
                  obj.bizId = companyInfoList[0].companyId
                  
                }
                this._getList(obj).then(res => {
                  resolve(res)
                })
              }
            })
          })
        }
        
        return new Promise((resolve) => {
          if(this.isEnd) {
            resolve()
          } else {
            return this._getList(obj)
          }
        })
      }
      
    },
    _getList(obj) {
      const self = this
      return new Promise((resolve) => {
        this.$load.show('加载中...')
        postApi.postQuery(obj).then(res => {
          this.$load.hide()
          if (res.data) {
            
            self.list.push(...res.data.postsList)
            self.initInfo(res.data)
            // 恢复下来刷新状态
            self.isLoading = false
            if (!res.data.endFlag) {
              self.pageNo++
              this.endTitle = "正在加载中..."
            } else {
              this.isEnd = true
              // 底部到底提示
              this.endTitle = res.data.endTitle
            }
            resolve(res.data)
          } else {
            resolve()
          }
        })
      })
    },
    initInfo(res) {
      // 通知栏
      this.noticeMsg = res.noticeMsg
      this.set_noticeMsg(res.noticeMsg)
      
      // 更新提醒
      if (res.remindMeTips) {
        this.$toast({
          message: res.remindMeTips,
          position: 'top'
        })
      }
    },
    _initList() {
      this.pageNo = 1
      this.list = []
      this.isEnd = false
      this.isLoading = false
      this.isShowFirst = false
    },
    _deletePost(e) {
      const {index} = e
      this.list.splice(index, 1)
    },
    _updatePost(e) {
      const {index, postsId} = e
      postApi.postDetail({
        postsId: postsId
      }).then(res => {
        if (res.data) {
          this.list.splice(index, 1, res.data.postsInfo)
        }
      })
    }
  },
}