export default {
  methods: {
    restoreOffsetTop(name, fn) {
      const top = this.$router.history.current.meta?.offsetTop
      const tab = this.$router.history.current.meta.tab
      console.log("restoreOffsetTop ====,", top)
      if (tab == name) {
        fn && fn(top)
      }
    }
  }
}