<template>
  <div>
    <div v-if='!show'>
      <img style="max-width: 300px" class="img margin_auto" src="../../assets/empty.png" alt="">
      <p class="tip">还没有内容哟~</p>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data: ()=> ({

  }),
  props: {
    show: Boolean
  }
}
</script>

<style lang="less" scoped>
.tip {
  font-size: 14px;
  color: #C1CAD4;
  text-align: center;
  margin-top: 17px;
}
.img {
  margin-top: 20vh;
  width: 50%;
  height: auto;
}
</style>