<template>
  <div class="f_flex f_a_s infoPanel">
    <div class="f_s0" style="margin-right: 11px;">
      <div class="f_flex f_col f_a_c f_j_c">
        <div class="avatar" @click='goGroupInfo'>
          <img style="width: 100%; height: 100%" :src="info.postsUserAvatar" alt="">
        </div>
        <div v-if='info.urgentStatus === 2' class="status f_flex f_a_c f_j_c">紧急</div>
        <!-- <div class="waitpass subTitle" v-if='info.auditingStatus != 1'>
          {{authStatus[info.auditingStatus]}}
        </div> -->
      </div>
    </div>
    
    <div class="f_g1">
      <div class="f_flex f_a_e f_w">
        <div class="name">{{info.postsUserName}}</div>
        <div class="part">{{getPostName}}</div>
        <div class="part_num theme_c">{{info.styleNo}}</div>
      </div>
      <div class="f_flex f_a_c f_w" style="margin-top: 3px">
        <div class="tagItem" v-for='(item, index) in info.labelList' :key="index">#{{item.labelName}}#</div>
      </div>
      <div class="content" v-html="content"></div>

      <div>
        <img
          v-for='(item, index) in info.postsImgUrls'
          :key='index'
          class="imgItem"
          :src="item" alt="" />
      </div>
      <div class="f_flex f_a_c" style="margin-top: 7px" v-if='info.locationAddress' @click='goMap'>
        <img style="width: 10px; height: 14px; margin-right: 2px" src="../../assets/icon_location.png" alt="">
        <p class="subTitle">{{info.locationAddress}}</p>
      </div>

      <div class="btnGroup f_flex f_a_c f_j_sa">
        <button class="btn btn_radius btnItem rejectBtn" @click='btnFn(2)'>拒绝</button>
        <button class="btn btn_radius btnItem theme_bg" @click='btnFn(1)'>通过</button>
      </div>
    </div>
  </div>
</template>

<script>
import postApi from '@/api/post'

export default {
  data: ()=> ({
    isZan: false,
    isStar: false,
    canDelete: false,
    authStatus: ['', '已审核', '审核未通过', '审核中'],
    placeholder: '说点什么...'
  }),
  computed: {
    content() {
      let c = this.info.content
      const remind = this.info.beRemindUserList || []
      for(let i=0; i<remind.length; i++) {
        c = c.replace(/(\$\{\})/, () => {
          return `<span class="atNameItem theme_c"> @${remind[i].userName}</span>`
        })
      }
      return c
    },
    getPostName() {
      const source = this.info.postsSource
      const name = this.info.groupName
      if(source) {
        return source == 1 ? name : (source == 2 ? '广场' : '私密本')
      }
      return name
    }
  },
  created() {
    this.isStar = this.info.hasAttention === 1
    this.isZan = this.info.hasLiked === 1
    this.canDelete = this.info.canDelete === 1
  },
  props: {
    info: {
      type: Object,
      default: ()=> ({})
    },
    groupId: {
      type: [Number,String],
      default: 0
    },
    companyId: {
      type: [Number,String],
      default: 0
    },
    index: {
      type: [Number,String],
      default: 0
    }
  },
  methods: {
    goMap() {
      this.$router.push(`/map?lng=${this.info.longitude}&lat=${this.info.latitude}&flag=${false}`)
    },
    goGroupInfo() {
      const status = this.info.auditingStatus
      if (status == 1) {
        const id = this.groupId
        const companyId = this.companyId
        this.$router.push(`/group/info?groupId=${id}&companyId=${companyId}`)
      }
    },
    btnFn(state) {
      postApi.postAuditing({
        postsId: this.info.postsId,
        auditingStatus: state
      }).then(res => {
        if(res.data) {
          this.$toast(res.data.msg)
          this.$emit('resolve', {state, index: this.index})
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.avatar {
  width: 44px;
  height: 44px;
  border-radius: 40px;
  overflow: hidden;
}
.subTitle {
  font-size: 13px;
  line-height: 18px;
  color: #9B9B9B;
}
.iconRirhtArrow {
  font-size: 16px;
  color: #999;
  margin-left: 4px;
  line-height: 18px;
}
.infoPanel {
  width: 100%;
  padding-top: 18px;
  padding-bottom: 14px;
  .waitpass {
    margin-top: 6px;
  }
  .status {
    background: #EA574C;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 4px;
    line-height: 13px;
    color: #fff;
    margin-top: 8px;
  }
  .name {
    font-size: 16px;
    color: #2D2D2D;
    margin-right: 15px;
    font-weight: 400;
    line-height: 22px;
  }
  .part {
    font-size: 13px;
    color: #4A4A4A;
    margin-right: 15px;
    font-weight: 400;
    line-height: 18px;
  }
  .part_num {
    font-size: 13px;
    line-height: 18px;
  }
  .tagItem {
    font-size: 14px;
    margin-right: 5px;
    
  }
  .content {
    font-size: 15px;
    color: #2D2D2D;
    line-height: 22px;
    margin: 4px 0;
  }
  .atNameItem {
    padding-right: 10px;
  }
  .imgItem {
    width: 79px;
    height: 79px;
    margin-right: 8px;
    margin-bottom: 6px;
  }
  
}

.btnGroup {
  margin-top: 16px;
}
.rejectBtn {
  border: 1px solid #D6D6D6;
  color: #999;
  background: #fff;
}
.btnItem {
  width: 116px;
  height: 32px;
  font-size: 14px;
}
</style>
