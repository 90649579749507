<template>
  <tab :name='name'>
    <div slot='title' class="tabTitle" :style='{fontSize: act==this.name ? "17px" : "14px"}'>{{title}}</div>
    <div @scroll="scrollFn" ref='wrap' class="tabPage">
      <van-pull-refresh v-model="isLoading" @refresh="init">
        <div>
          <div style="height: 44px;" v-if='get_noticeMsg && get_noticeStatus && showNotice'></div>
          <empty-page :show='list.length>0'>
            <div class="homeContent" ref='content'>
              <info-panel 
                v-for='(item, index) in list' 
                :key='index'
                @delete='_deletePost'
                :index='index'
                :keywordEvent='styleNoEvent'
                :showStar='showStar'
                :info='item' />
              <div class="endTitle" v-if='list.length && isEnd'>{{endTitle}}</div>
            </div>
          </empty-page>
        </div>
      </van-pull-refresh>
    </div>
  </tab>
</template>

<script>
import { Tab, PullRefresh } from 'vant';
import emptyPage from '@/components/emptyPage/index.vue'
import getList from '../mixins/getList'
import infoPanel from '@/components/infoPanel/index.vue'
import getStoreMixins from '@/pages/home/mixins/getStore'

export default {
  components: {
    Tab,
    emptyPage,
    infoPanel,
    'van-pull-refresh': PullRefresh
  },
  props: {
    act: String,
    name: String,
    title: String,
    bizType: Number,
    refresh: {
      type: Number,
      default: 0
    },
    styleNoEvent: {
      type: Boolean,
      default: true
    },
    showStar: {
      type: Boolean,
      default: true
    }
  },
  mixins: [getList, getStoreMixins],
  watch: {
    refresh() {
      this.init()
    }
  },
  methods: {
    scrollFn(e) {
      this.$global.debounce(()=> {
        this.$router.history.current.meta.offsetTop = e.target.scrollTop
        const wrapH = this.$refs?.wrap?.clientHeight
        const contentH = this.$refs?.content?.clientHeight
        if (wrapH && contentH) {
          if (contentH - e.target.scrollTop - wrapH <= 80) {
            this.getList(this.bizType)
          }
        }
      })
    },
    init() {
      if (this.act === this.name) {
        this._initList()
        this.showNotice = true
        this.$router.history.current.meta.tab = this.name
        this.getList(this.bizType)
        this.noticeFn()
      }
    },
    scrollTopFn(h) {
      setTimeout(() => {
        this.$refs.wrap.scrollTop = h
      }, 20)
    },
    noticeFn() {
      this.showNotice = true
      const t = 33 * 1000
      setTimeout(() => {
        this.showNotice = false
      }, t)
    }
  },
  // mounted() {
  //   // this.init()
    
  // }
}
</script>

<style lang="less" scoped>
@import url('~@/pages/home/mixins/tabStyle.less');
</style>