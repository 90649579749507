<template>
  <tab :name='name' >
    <div slot='title' :style='{fontSize: act==this.name ? "17px" : "14px"}'>待办</div>
    <div class="tabPage" ref='wrap' @scroll="scrollFn">
      <pull-refresh  v-model="isLoading" @refresh="init">
        <div>
          <div style="height: 44px;" v-if='get_noticeMsg && get_noticeStatus && showNotice'></div>
          <empty-page :show='list.length>0'>
            <div class="homeContent" ref='content' v-if='list.length'>
              <info-panel 
                v-for='(item, index) in list' 
                :key='index'
                :index='index'
                @resolve='_deleteItem'
                :info='item' />
              <div class="endTitle" v-if='list.length && isEnd'>{{endTitle}}</div>
            </div>
          </empty-page>
        </div>
      </pull-refresh>
    </div>
  </tab>
</template>

<script>
import { Tab, PullRefresh } from 'vant';
import emptyPage from '@/components/emptyPage/index.vue'
import getList from '../mixins/getList'
import infoPanel from '@/components/infoPanel/examine.vue'
import getStoreMixins from '@/pages/home/mixins/getStore'

export default {
  components: {
    Tab,
    emptyPage,
    infoPanel,
    PullRefresh
  },
  props: {
    act: String,
    refresh: {
      type: Number,
      default: 0
    }
  },
  data: ()=> ({
    isShowFirst: false,
    name: 'admin',
    bizType: 5,
    showNotice: true
  }),
  mixins: [getList, getStoreMixins],
  watch: {
    refresh() {
      this.init()
    }
  },
  methods: {
    scrollFn(e) {
      this.$global.debounce(()=> {
        const wrapH = this.$refs.wrap.clientHeight
        const contentH = this.$refs.content.clientHeight
        if (contentH - e.target.scrollTop - wrapH <= 80) {
          this.getList(this.bizType)
        }
        this.$router.history.current.meta.offsetTop = e.target.scrollTop
      })
    },
    init() {
      if (this.act === this.name) {
        this.$router.history.current.meta.tab = this.name
        this._initList()
        this.getList(this.bizType)
        this.noticeFn()
      }
    },
    _deleteItem(e) {
      const {index} = e
      this.list.splice(index, 1)
    },
    scrollTopFn(h) {
      setTimeout(() => {
        this.$refs.wrap.scrollTop = h
      }, 20)
    },
    noticeFn() {
      this.showNotice = true
      const t = 33 * 1000
      setTimeout(() => {
        this.showNotice = false
      }, t)
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/pages/home/mixins/tabStyle.less');
</style>