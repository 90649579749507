<template>
  <div class="simpleInfo f_flex underline" @click='goDetail'>
    <div class="avatarPanel">
      <img class="avatar" :src="info.userAvatar" alt="">
      <div class="news" v-if='info.readStatus === 1'></div>
    </div>
    <div class="middle f_g1">
      <div class="title">{{info.remindUserName}}</div>
      <div class="content" v-html='content'></div>
      <div>
        <img v-if='info.remindType === 3' class="iconInfo" src="../../assets/icon_zan.png" alt="">
      </div>
      <div class="date">{{info.remindTime}}</div>
    </div>
    <div class="right f_s0" v-if='info.postsImgUrls && info.postsImgUrls.length'>
      <img class="pic" v-if='info.postsImgUrls[0]' :src="info.postsImgUrls[0]" alt="">
      <div class="zanContent">{{zanContent}}</div>
    </div>
  </div>
</template>

<script>
import postApi from '@/api/post'

export default {
  data: ()=> ({
    zanContent: ''
  }),
  props: {
    info: {
      type: Object,
      defalult: ()=> ({})
    }
  },
  computed: {
    content() {
      let c = this.info.remindContent
      let remindUserType = this.info.remindUserType
      // const type = this.info.remindType // 1-贴子提醒 2-回复 3-点赞
      const remind = this.info.beRemindUser || []
      let str = ''
      if (remindUserType && remindUserType == 3){
        str = `<span class="atNameItem theme_c"> @所有人</span>`
      } else {
        for(let i=0; i<remind.length; i++) {
          str += `<span class="atNameItem theme_c"> @${remind[i].userName}</span>`
        }
      }
      return c + str
    },
  },
  methods: {
    goDetail() {
      const status = this.info.readStatus // 1 未读   2 已读
      if (status === 1) {
        this.readNews()
      }
      this.$router.push(`/detail?postsId=${this.info.postsId}`)
    },
    readNews() {
      postApi.readNews({
        remindId: this.info.remindId
      })
    }
  },
}
</script>


<style lang="less" scoped>
.simpleInfo {
  position: relative;
  box-sizing: border-box;
  padding-top: 6px;
  margin-left: 10px;
}
.avatarPanel {
  position: relative;
  margin-right: 10px;
  .avatar {
    width: 44px;
    height: 44px;
    border-radius: 40px;
  }
  .news {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 15px;
    right: -4px;
    top: 0;
    background: #EA574C;
  }
}
.middle {
  .title {
    font-size: 14px;
    color: #2D2D2D;
    margin-bottom: 2px;
  }
  .content {
    font-size: 13px;
    line-height: 17px;
    color: #2D2D2D;
    word-break: break-all; 
    word-wrap: break-word;
  }
  .date {
    font-size: 12px;
    color: #9B9B9B;
    margin-top: 2px;
    margin-bottom: 7px;
  }
  .iconInfo {
    width: 19px;
    height: 19px;
  }
}
.right {
  width: 54px;
  margin: 0 10px;
  .pic {
    width: 100%;
    height: 54px;
  }
  .zanContent {
    width: 100%;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    line-height: 17px;
    font-size: 12px;
    color: #9B9B9B;
    margin-bottom: 5px;
  }
}
</style>